import { Switch, Redirect, Route } from "react-router-dom";
import { Public_Profix, Public_WhiteList, Public_Minting } from "../../config";
import Home from "./home";
import Mint from "./mint";
import Errors from "./404error";
import Whitelist from "./whitelist";
const PublicPage = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to={Public_Profix} />
      </Route>
      <Route path={Public_Profix} component={Home} />
      <Route path={Public_Minting} component={Mint} />
      <Route path={Public_WhiteList} component={Whitelist} />
      <Route path="/*" component={Errors} />
    </Switch>
  );
};
export default PublicPage;
